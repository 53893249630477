<template>
  <th-page-wrapper>
    <th-datatable
      :key="$route.fullPath"
      ref="stockTakingsTable"
      sortable
      :headers="headers"
      do-route
      do-route-filters
      :locale="locale"
      :show-operations="false"
      multiple-select
      resource="stockTakings"
      route-base="/inventory/stock_takings"
      :buttons="buttons"
      show-search-filter
      :resource-query="{ query: { deleted: false, size: 100 } }"
      @selection-change="handleSelectionChange"
      @loading-error="handleLoadingError"
    >
      <template #actions>
        <div class="actions">
          <actions
            v-permissions="{
              scopes: ['inventory:stock_takings:delete']
            }"
            :selected-items="selectedItems"
            @delete-requested="handleDelete"
          />
        </div>
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import Actions from '@components/actions/delete'
import { useMessagesStore } from '@/store/messages'
import { useExportsStore } from '@/store/exports'

export default {
  name: 'StockTakingsAll',
  metaInfo() {
    return {
      title: this.$t('nav.main.items.inventory.stock_takings')
    }
  },
  components: {
    Actions
  },
  data() {
    return {
      locations: [],
      resourceQuery: {
        deleted: false
      },
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'description',
          label: this.$t('common.headers.description.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['inventory:stock_takings:create']
        },
        {
          type: 'custom_export',
          scopes: ['inventory:stock_takings:export'],
          clickHandler: ({ resourceOptions }) => {
            this.handleExport({ resourceOptions })
          }
        }
      ],
      selectedItems: []
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  async mounted() {},
  methods: {
    refresh() {
      this.$refs.stockTakingsTable.refresh()
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.stock_takings.title')
        })
      })
    },
    async handleExport({ resourceOptions }) {
      const query = {
        ...this.resourceQuery,
        ...resourceOptions.query,
        format: 'csv'
      }

      try {
        const {
          data
        } = await th
          .analyticsHandlersV1()
          .analytics.reports.AnalyticsReportsStockTakings.getAll(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.stock_takings.title',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV1',
              path: 'analytics.reports.AnalyticsReportsStockTakings',
              handler: 'getAll',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.stock_takings.title')
          })
        })
      }
    },
    async handleDelete(payload) {
      const confirm = await this.$askToDeleteMany(
        payload,
        this.$t('common.delete_confirm.object_names.stock_takings'),
        'name'
      )
      if (confirm) {
        if (payload.length > 1) {
          return this.handleBulkDelete(this.selectedItems)
        }
        return this.deleteResource(payload)
      }
    },

    async deleteResource(payload) {
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.stock_taking.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.stock_taking.singular')
      })

      try {
        const inst = th.stockTakings()
        await inst.delete(payload[0].id)
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.refresh()
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    handleBulkDelete(items) {
      const inst = th.stockTakings()

      const operations = items.map((item) => {
        return () => inst.delete(item.id)
      })

      const label = this.$t('common.error.action.delete.multiple', {
        resources: this.$t('common.resource.stock_taking.plural')
      })

      const currentRoute = this.$route.fullPath

      const fulfillment = () => {
        if (this.$route.fullPath === currentRoute) {
          this.refresh()
        }
      }

      useMessagesStore().startLocalOperation({
        operations,
        label,
        fulfillment
      })
    },
    handleSelectionChange(val) {
      this.selectedItems = val
    }
  }
}
</script>

<style scoped></style>
